import * as React from 'react'
import Anvandarvillkor from '/src/components/villkor'
import Layout from '/src/components/layout'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faMobileScreenButton, faGlobe, faUserGear } from '@fortawesome/free-solid-svg-icons'

const PersonuppgiftspolicyPage = () => {
	return (
		<Layout pageTitle="Personuppgiftspolicy">

            
			<p style={{fontFamily: 'sans-serif'}}>Genom att godkänna dessa villkor samtycker du till villkoren i sin helhet, inklusive att personuppgifter kring dig lagras och behandlas inom ramen för tjänsten.</p>

			<p>&nbsp;</p>
			<hr style={{border: '1px solid gray'}} />
			<p>&nbsp;</p>

			<Anvandarvillkor></Anvandarvillkor>

			<p>&nbsp;</p>
			<hr style={{border: '1px solid gray'}} />
			<p>&nbsp;</p>

		</Layout>
	)
}

export default PersonuppgiftspolicyPage